.B8 #lin{
    width: 1px;
    height: 100vh;
    position: absolute;
    right: 0px;
    border-right: 1px dashed #B1B1B1;
    top:0px;
    margin-top: -95px;
}

.B8 #h1{
    position: absolute;
    bottom: 15vh;
    right: 15vw;
}

.B8 #h2{
    position: absolute;
    bottom: 20vh;
    left: 25vw;
}

@media screen and (max-width: 1400px) {
    .B8 #h1{
        position: absolute;
        bottom: 5vh;
        right: 5vw;
    }
    
    .B8 #h2{
        position: absolute;
        bottom: 20px;
        left: 15vw;
    }
}

@media screen and (max-width: 1100px) {
    .B8 p{
        font-size: 14px;
        text-align: right;
    }
    .B8 h3{
        text-align: right;
    }
    .B8 #h1{
        position: absolute;
        bottom: 20px;
        right: 20px;
        height: 200px;
        width: 200px;
        object-fit: contain;
    }
    
    .B8 #h2{
        position: absolute;
        bottom: 20px;
        left: 5vw;
        height: 200px;
        width: 200px;
        object-fit: contain;
    }
}

@media screen and (max-width: 1000px) {
    .B8 #h1{
        position: absolute;
        bottom: 20px;
        right: none;
        left: 5vw;
        height: 100px;
        width: 100px;
        object-fit: contain;
    }
    
    .B8 #h2{
        position: absolute;
        bottom: 20px;
        left: 5vw;
        height: 100px;
        width: 100px;
        object-fit: contain;
    }
}
@media screen and (max-width: 800px) {
    .B8 #h1,.B8 #h2{
        display: none;
    }
    
}