.B5{
    background: black !important;
    color:white;
    
}

.B5 .ticet p{
    color:white;
}

.B5 #horse{
    position: absolute;
    bottom: -400px;
    left: -130px;
    transition: 2.2s;
    width: auto;
    height: auto;
}

.position_1 .B5 #horse{
    
    bottom: -180px;
 
}
.position_0 .B5 #horse{
    bottom: 0px;
 
}
.B5 .bigImg{
    opacity: 0;
    transition: 3s;
}
.position_0 .B5 .bigImg,.position_1 .B5 .bigImg{
    opacity: 1;
}

.B5 #bottomTXT{
    position: absolute;
    width: 35vw;
    bottom: 0px;
    left: 400px;
    opacity: 0;
    transition:  2.5s ;
    font-size: 14px;
}



.position_1 #bottomTXT{
    opacity: 1;
    bottom: 80px;
}

.position_0 .B5 #bottomTXT{
    bottom: 150px;
 
}

@media screen and (max-width: 1400px) {
    .B5 #bottomTXT{
       
        width: 50vw;
        bottom: 80px;
        
    }
    .B5 .cont p{
        font-size: 13px;
    }
    

    .position_0 .B5 .bigImg,.position_1 .B5 .bigImg{
        opacity: 0.4;
    }
    .B5 #horse{
        width: 400px !important;
        height: 400px !important;
    }
    .position_1 .B5 #horse,
    .position_0 .B5 #horse{

        bottom: -100px;
        left: -90px;
    }
    .B5 #bottomTXT{
       font-size: 14px;
     }
    
}


@media screen and (max-width: 1000px), screen and (max-height: 720px){
   
    .B5 #bottomTXT{
       display: none !important;
    }
    .B5 #horse{
        width: 250px !important;
        height: 250px !important;
    }
    .position_0 .B5 #horse,
    .position_1 .B5 #horse{

        bottom: -90px;
        left: -50px;
    }
}