.Catalog .item img{
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    height: 17vh;
    position: relative;
    z-index: 0;
}
.Catalog .item{
    height: 100%;
    
}

.Catalog .item h4{
   font-size: 13px;
   margin-bottom: 0px;
   padding:5px;
}
.Catalog .item p{
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 12px;
    margin-top: -12px;
    padding:5px;
   
 }



 .Catalog .item:hover .bay{
     opacity: 1;
 }


 .Catalog .item .bay{
    opacity: 0;
     width: 45px;
     height: 41px;
     transition: .3s;
     cursor: pointer;
     margin-top: 3px;
     object-fit: contain;
     margin-right: 3px;
     display: block;
     padding: 8px;
     background: white;
 }

 .Catalog .item .bay:hover{
     box-shadow: 5px 5px 30px rgba(0,0,0,0.05);
 }
 .Catalog .item .bay:active{
     transform:scale(0.5);
 }