body{
    background: black !important;
}
.blockCont{
    height: 100vh;
    overflow: hidden;
}

.block{
    height: 100vh;
    width: 100vw;
    transition:2.3s;
    position: fixed;

    top:0;
    box-shadow: 0 0 200px rgba(0, 0, 0, 0);
    left: 0;
    overflow: hidden;
    background: white;
}

.block .bb{
    height: 100vh;
    background: white;
}

.block.position_0{
    top: -300px;
}
.block.position_1{
    top: 0px;
    box-shadow: 0 0 200px rgba(0, 0, 0, 0.2);
    
}
.block.position_2{
    top: 100vh;
}



@media screen and (max-width: 800px) {
  
    .block{
        height: auto;
       
        position: relative;
       
        box-shadow: none;
        
        overflow: auto;
        
    }

    body,html,.block,.MainPage,.App ,#root,.blockCont  {
        width: 100vw !important;
        height: auto !important;
        overflow: hidden !important;
        min-height: 100vh;
       
      }

      body,html{
        overflow-y: scroll !important;
      }

      .block{
        margin-bottom: -1px;
      }
      .block .bb{
          height: auto;

      }
      .B1{
        min-height: 100vh;
      }
      .B2 .alert{
        position: relative !important;
        margin-top: 30px;
        left: 0 !important;
        margin-left: auto;
        font-size: 12px !important;
        margin-right: 4px;
      }

    .block.position_0{
        top: 0px;
    }
    .block.position_1{
        top: 0px;
        
        
    }
    .block.position_2{
        top: 0px;
    }

    .ticet_cont{
        position: absolute !important;
    }

    .B5{
        height: auto !important;
    }
    .B6{
        height: auto !important;
    }
    
    .B6 .bigImg{
        height: 50vh !important;
    }

    .B10{
        height: auto !important;
    }
    .B10 .bigImg{
        height: 300px !important;
    }




    .ticet{
        height: 100% !important;
    }

    .row.cont{
        height: 100% !important;
    }
    .B5.bb{
        height: 650px !important;
    }
}