.B1{
    background: black;
    
}

.B1 #line{
    background: rgba(255, 255, 255, 0.2);
    height: 1px;
    width: 100vw;
    position: absolute;
    top: 50vh;
    z-index:1;
}
.B1 #horse{
    z-index: 2;
    
}

.B1 .bigImg{
    transition: 1.5s;
    top: 0px;
}
.position_0 .B1 .bigImg{
    top: -50px;
}
.B1 .bigImg{
    position: absolute;
}

.B1 #imgs{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

.B1 #txt{
    position: absolute;
    z-index: 2;   
    top: calc(50vh - 132px);
    right: 4vw;
    width: max-content;
}

.B1 #txt h1{
    font-size: 105px;
    color: white;
    margin: 0;
    padding: 0;
    z-index: 1;
    position: relative;
}

.B1 #desig{
    background: black;
    width: max-content;
    color:white;
    font-size: 35px;
    margin: 0;
    padding: 0 ;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -3px;
    margin-top: -20px;
    z-index: 0;
    position: relative;
}

.B1 .menus{
    color: white;
    display: inline-block;
    padding-right: 7px;
    padding-left: 7px;
    margin-right: 22px;
    cursor: pointer;
    transition: .6s;
    opacity: 0.9;
    text-shadow: 0px 5px 15px rgba(255, 255, 255, 0);
}

.B1 .menus:hover{
    opacity: 1;
    text-shadow: 0px 5px 15px white;
}

.B1 #treg{
    position:absolute;
    z-index: 10;
    background: white;
    width: 1000px;
    height: 200px;
    transform: rotate(-10deg);
    bottom: -110px;
    right: -30px;
    transition: 2s;
}


.B1{
    background: black !important;
}

.B1 .bigImg{
    width: 100vw;
    height: 100vh;
}

@media screen and (max-width: 1800px), screen and (max-height: 620px) {

}



@media screen and (max-width: 1800px) {
    
    .B1 .bigImg{
        left: -600px;
        width: 1920px;
    }
    .B1 #treg{
        bottom: -180px;
        right: -300px;
        height: 300px;
        width: 1500px;
    }
}


@media screen and (max-width: 1250px) {
    
    .B1 .bigImg{
        left: -800px;
        width: 1920px;
    }
    .B1 #treg{
        bottom: -180px;
        right: -300px;
        height: 300px;
        width: 1500px;
    }
}
@media screen and (max-width: 1020px) {
    
    .B1 #line{
        top:142px;
    }
    .B1 #txt{
        top:10px;
    }
    
}

@media screen and (max-width: 700px) {
    .B1 #line{
        top:77px;
    }
    .B1 #txt{
        left: 15px;
        width: 100vw;
    }
    
    .B1 #txt h1{
        font-size: 50px;
    }
    .B1 #txt #desig{
        font-size: 20px;
        margin-top: -10px;
        margin-left: -8px;
    }
    .B1 #txt .menus{
        font-size: 14px;
        padding: 0px;
        
    }
    
}