.B7 .card{
    border:none;
   
    border-radius: 10px;
    overflow: hidden;
    height: 100%;
    padding-bottom: 20px;
    transition: .3s;
}



.B7 .card:hover a{
    opacity: 1;
}

.B7 .card-text{
    font-size: 14px;
}

.B7 img{
    border-radius: 10px;
    object-fit: cover;
}

.B7 .card a{
    position: absolute;
    bottom: 15px;
    opacity: 0;
    transition: .3s;
}





@media screen and (max-width: 1400px) {
    .B7 .card h5{
        font-size: 15px !important;
        margin-bottom: 0px !important;
    }
    .B7 .card .card-text{
        font-size: 13px !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
}

@media screen and (max-height: 760px) {
    .B7 .card img{
        max-height: 20vh;
    }
}
@media screen and (max-height: 600px) {
    .B7 .card img{
        max-height: 10vh;
    }
}