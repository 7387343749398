.window_body{
    background: white;
    width: 98vw;
    
    transition: .6s ;
    margin-left: 1vw;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 1vw;
    
}
.window{
    
    backdrop-filter: blur(9px);
    background: rgba(0,0,0,0.1);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top:0px;
    left: 0px;
    z-index: 99999;
    overflow: auto;
    transition: opacity .4s;
}
.window .close{
    position: absolute;
    font-weight: 300;
    top: 10vh;
    padding-top: 6px;
    left: 99vw;
    transform: translate(-25px, 0px);
    cursor: pointer;
    transition: .3s, top .6s;
    opacity: 0.5;
}
.window .close:hover{
    opacity: 1;
}

.window.v_0{
    display: none;
    opacity: 0;
}
.window.v_1{
    display: block;
    opacity: 0;
}
.window.v_2{
    display: block;
    opacity: 1;
}

.window.v_0 .window_body,
.window.v_1 .window_body{
    margin-top:0vh;
}

.window.v_2 .window_body{
    margin-top:10vh;
}

.window.v_0  .close,
.window.v_1  .close{
    top:0vh;
}


.window.v_2  .close{
    top:10vh;
}