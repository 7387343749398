.bigImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
f{
  color:#86EA86
}

h1,h2,h3,h4,h5,h6{
  font-family: "Montserrat";
}
div,p,a,li,ul,table{
  font-family: "Raleway";
  font-weight: 300;
}

b{
  font-weight: 700 !important;
}

.greenBar{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 80px;
  background: #86EA86;
  z-index: 100;
  color:black;
}


::-webkit-scrollbar { width: 3px; height: 3px;}
::-webkit-scrollbar-button { height: 0px; background-color: #666; }
::-webkit-scrollbar-track {  height: 0px;background-color: #999;}
::-webkit-scrollbar-track-piece { height: 0px; background-color: transparent;}
::-webkit-scrollbar-thumb { height: 0px; background-color: #666; border-radius: 3px;}
::-webkit-scrollbar-corner {height: 0px; background-color: #999;}
::-webkit-resizer { height: 0px; background-color: #666;}

.ticet{
  height: 100vh;
  border-right: 1px dashed #B1B1B1;
}
#root input{
  font-family: "Arial";
}
#root .form-control{
  background: #f5f6f9;
  border: none;
}
.ticet p{
  -webkit-writing-mode: vertical-rl; writing-mode:tb-rl;
  transform: rotate(180deg) translate(0,50%);
  height: max-content;
  transform-origin: center;
  top:50vh;
  position: absolute;
  left: calc(50% - 20px);
  color:black;
  letter-spacing: 15px;
  padding: 0;
  margin: 0;
  
  width: max-content;
}

#root .btn{
  border: none !important;
  box-shadow: none !important;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}

#root .btn-dark{
  background: rgba(0,0,0,0.1) ;  
}

#root .btn-dark:hover{
  background: rgba(0,0,0,0.15) ;  
}

.but{
  font-weight: 700;
  letter-spacing: 3px;
  cursor: pointer;
  transition: .2s;
  width: max-content;
  border-bottom: 2px solid transparent;

}

#topArrow{
  position: fixed;
  z-index: 100;
  display: block;
  bottom: 10px;
  left: 10px;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  box-shadow: 5px 5px 30px rgba(0,0,0,0.0);
  transform:scaleY(-1);
  background: rgba(255,255,255,0.5);
  padding: 10px;
  cursor: pointer;
  transition: .2s;
  opacity: 1;
}

.hidden{
  opacity: 0 !important;
}

#topArrow:hover{
  box-shadow: 5px 5px 30px rgba(0,0,0,0.1);
  bottom: 10px;
  height: 50px;
  padding-top: 20px;
  padding-bottom: 0px;
}
.but:hover{
border-bottom: 2px solid black;
}
.but.light{
  color: white;
}

.btn-green{
  background: #86EA86 !important;
  color:#152415 !important;
}

.btn-green:hover{
  background: #79d179 !important;
}

.but.light:hover{
  border-bottom: 2px solid white;
  }

  #alert{
    position: fixed;
    width: 100vw;
    height: 50px;
    background: #1b2737;
    z-index: 99;
    bottom: 0px;
    color: white;
    text-align: center;
    line-height: 50px;
    font-family: Arial, Helvetica, sans-serif;
  }

  @media screen and (max-width: 1200px) {
    p,h1,h2,h3,h4,h5,a,div{
      font-size: 14px;
    }

  }

  @media screen and (max-width: 800px) {
    .greenBar{
     height: 100px;
    }
    

  }

  

  
 