.B2 h1{
    margin-top: 20vh;
}

.B2 img{
    margin-top: 15vh;
    margin-left: 150px;
    transition: 2s; 
    width: 103%;
    height: auto;
   
}

.position_0 .B2 img, .position_1 .B2 img{
    margin-left: 0px;
}

.B2 h1{
    line-height: 36px;
    font-size: 36px;
    font-family: "Montserrat";
}

.B2 .txt{
    
    font-size: 16px;
}
.B2 .alert{
    position: absolute;
    bottom: 20px;
    font-size: 14px;
    border:none;
    background: #f3f3f3;
    left:10vw;
    width:87vw;
}
@media screen and (max-width: 1500px) {

    .B2 img{
       
       margin-left: auto;
       display: block;
       margin-right: -15px;
    }

    .B2 h1{
        font-size: 30px;
    }
    .B2 .txt{
        font-size: 14px;
    }

    .position_0 .B2 img, .position_1 .B2 img{
        margin-left: auto;
    }
}

@media screen and (max-width: 1000px) {
    .B2 h1{
        font-size: 20px;
        line-height: 20px;
        margin-top: 100px;
    }
    .B2 img{
       
        margin-top: 50px;
    }
    .block{
        min-height: 0;
    }
    .B6 {

        min-height: 0;

    }
}