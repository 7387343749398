.B6{
    background: black !important;
    color:white;
}
.B6 .bigImg{
    height: 100vh;
    object-fit: cover;
    margin-right: auto;
    display: block;
    width:100%;
    transition: 2.2s;
}

